import { h, Component } from "preact";

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      left: null,
      top: null,
    };
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.selected && !this.video.paused) {
      this.video.pause();
    }
    if (newProps.selected && this.video.paused) {
      this.center();
      this.video.play();
    }
  }

  componentDidMount() {
    this.video = this.base.firstChild;
    this.video.addEventListener("click", () => {
      if (!this.props.selected) {
        this.props.doSelectVideo(this.props.name);
      } else {
        this.props.doSelectVideo(null);
      }
    });

    this.video.addEventListener("ended", () => {
      this.props.doVideoEnded();
    });
  }

  center() {
    const { viewportWidth, viewportHeight } = this.props;

    const { left, right, top, bottom } = this.base.getBoundingClientRect();

    const screenMiddleX = viewportWidth / 2;
    const screenMiddleY = viewportHeight / 2;

    const currentVideoMiddleX = (left + right) / 2;
    const currentVideoMiddleY = (top + bottom) / 2;

    const leftAdjustment = screenMiddleX - currentVideoMiddleX;
    const topAdjustment = screenMiddleY - currentVideoMiddleY;

    const { width, height } = this.video.getBoundingClientRect();

    const scaleY = viewportHeight / height;
    const scaleX = viewportWidth / width;

    const scale = Math.min(scaleX, scaleY) - 0.2;

    this.setState({
      left: leftAdjustment,
      top: topAdjustment,
      scale,
    });
  }

  render({ url, selected }, { left = 0, top = 0, scale = 1 }) {
    return (
      <div
        className="w-50 w-33-ns relative flex items-center"
        style={{
          zIndex: selected ? 5 : 1,
          transition: "transform",
          transitionDuration: "300ms",
          transformOrigin: "center",
          transform: selected ? `translate(${left}px, ${top}px)` : "none",
        }}
      >
        <video
          preload="auto"
          disableRemotePlayback
          playsinline
          style={{
            zIndex: selected ? 5 : 1,
            transition: "transform",
            transitionDuration: "300ms",
            transformOrigin: "center",
            transform: selected ? `scale(${scale})` : "scale(1)",
          }}
          key={url}
          className="w-100"
        >
          <source src={url} type="video/mp4" />
        </video>
      </div>
    );
  }
}
