import { h } from "preact";
import { connect } from "redux-bundler-preact";
import Video from "./video";

export default connect(
  "selectVideos",
  "selectViewportWidth",
  "selectViewportHeight",
  "doSelectVideo",
  "doVideoEnded",
  ({ videos, viewportHeight, viewportWidth, doSelectVideo, doVideoEnded }) => (
    <div className="flex flex-wrap">
      {videos.map((item) => (
        <Video
          doSelectVideo={doSelectVideo}
          doVideoEnded={doVideoEnded}
          viewportHeight={viewportHeight}
          viewportWidth={viewportWidth}
          {...item}
        />
      ))}
    </div>
  )
);
