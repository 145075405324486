import { h, render } from "preact";
import { Provider } from "redux-bundler-preact";
import getStore from "./bundles";
import Layout from "./layout";

const store = (window.store = getStore());

render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  document.body
);
