import { createSelector } from "redux-bundler";

const sources = {
  ashley: require("../../videos/ashley.mp4"),
  emily: require("../../videos/emylee.mp4"),
  ff: require("../../videos/ff.mp4"),
  gregkimmy: require("../../videos/gregkimmy.mp4"),
  jacob: require("../../videos/jacob.mp4"),
  ed: require("../../videos/ed.mp4"),
  jeff: require("../../videos/jeff.mp4"),
  jonandashley: require("../../videos/jonandashley.mp4"),
  lauradavid: require("../../videos/lauradavid.mp4"),
  mayer: require("../../videos/mayer.mp4"),
  stevenson: require("../../videos/stevenson.mp4"),
};

export default {
  name: "videos",
  getReducer: () => {
    const initial = {
      selected: null,
    };
    return (state = initial, { type, payload }) => {
      if (type === "SELECTED") {
        return { ...state, selected: payload };
      }
      if (type === "ENDED") {
        const keys = Object.keys(sources);
        const index = keys.findIndex((key) => key === state.selected);
        let next = keys[index + 1] || null;
        return { ...state, selected: next };
      }
      return state;
    };
  },
  doSelectVideo: (id) => ({ type: "SELECTED", payload: id }),
  doVideoEnded: () => ({ type: "ENDED" }),
  selectVideosRaw: (state) => state.videos,
  selectVideos: createSelector("selectVideosRaw", (raw) => {
    const result = [];
    for (const key in sources) {
      const url = sources[key];
      result.push({
        name: key,
        url,
        selected: raw.selected === key,
      });
    }
    return result;
  }),
};
