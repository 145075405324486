import { debounce } from "redux-bundler";

const WINDOW_RESIZED = "WINDOW_RESIZED";

const getInnerWidth = () => window.innerWidth;
const getInnerHeight = () => window.innerHeight;

export default {
  name: "ui",
  init: (store) => {
    const handler = debounce(() => {
      store.dispatch({
        type: WINDOW_RESIZED,
        payload: { width: getInnerWidth(), height: getInnerHeight() },
      });
    }, 300);
    window.addEventListener("resize", handler, { passive: true });
  },
  getReducer: () => {
    const width = getInnerWidth();
    const height = getInnerHeight();
    const initialState = {
      width,
      height,
    };
    return (state = initialState, { type, payload }) => {
      if (type === WINDOW_RESIZED) {
        return Object.assign({}, state, payload);
      }
      return state;
    };
  },
  selectViewportWidth: (state) => state.ui.width,
  selectViewportHeight: (state) => state.ui.height,
};
